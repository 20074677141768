import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { WipWorkspaceSpec } from '../wip-form.model';

type AssaySelectorItem = Omit<WipWorkspaceSpec, 'assays'> & {
  assays: Array<{ name: string; index: number; workspace: WipWorkspaceSpec }>;
};

/**
 * Note for us...this needs to be refactored so that calling `writeValue` does not ALSO fire a onChange call.
 * The main issue with the current design is the input/outputs are logically different things. The `input` for the
 * control is a list of 'included' workspaces and their assays. The output is a list of 'excluded' workspaces/assays.
 * The data going in, needs to be the same as the data going out, so some other structure needs to be defined where the
 * input and output can be the same thing.
 */
@Component({
  selector: 'app-assays-selector',
  templateUrl: './assays-selector.component.html',
  styleUrls: ['./assays-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssaysSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AssaysSelectorComponent,
      multi: true,
    },
  ],
})
export class AssaysSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() set workspaces(value: Array<WipWorkspaceSpec>) {
    if (value === undefined) {
      return;
    }
    this._workspaces = value;
    this.writeValue(value);
  }
  _workspaces: Array<WipWorkspaceSpec>;
  included: Array<AssaySelectorItem> = [];
  includedSelected: Array<AssaySelectorItem> = [];
  excluded: Array<AssaySelectorItem> = [];
  excludedSelected: Array<AssaySelectorItem> = [];
  form = new UntypedFormGroup({
    include: new UntypedFormControl(),
    exclude: new UntypedFormControl(),
  });
  _onChange: (value: Array<WipWorkspaceSpec>) => void;
  _onTouched: () => void;
  includeValueChangesSub: Subscription;
  excludeValueChangesSub: Subscription;

  ngOnInit() {
    this.includeValueChangesSub = this.form.controls.include.valueChanges.subscribe((value) => {
      this.onTouch();
      this.includedSelected = value;
    });
    this.excludeValueChangesSub = this.form.controls.exclude.valueChanges.subscribe((value) => {
      this.onTouch();
      this.excludedSelected = value;
    });
  }

  validate(): ValidationErrors | null {
    let status;
    if (!this.included.length && !this.excluded.length) {
      status = null;
    } else {
      status = this.included.length > 0 ? null : { required: true };
    }

    return status;
  }

  assaysTrackBy(index, assay) {
    return assay.index;
  }

  move(event, fromInclude = true) {
    event.preventDefault();
    event.stopPropagation();
    this.onTouch();

    const source = fromInclude ? this.included : this.excluded;
    const target = fromInclude ? this.excluded : this.included;
    const sourceForm = fromInclude ? this.form.controls.include : this.form.controls.exclude;
    const sourceList = sourceForm.value;

    sourceList.forEach((sourceItem) => {
      const parentWorkspace = target.find(
        (targetItem) =>
          targetItem.name === sourceItem.workspace.name &&
          targetItem.workspaceCode === sourceItem.workspace.workspaceCode
      );
      if (parentWorkspace) {
        const assayIndex = parentWorkspace.assays.findIndex((assay) => assay.index > sourceItem.index);
        if (assayIndex !== -1) {
          parentWorkspace.assays.splice(assayIndex, 0, sourceItem);
        } else {
          parentWorkspace.assays.push(sourceItem);
        }
      } else {
        target.push({ ...sourceItem.workspace, assays: [sourceItem] });
      }
      const sourceItemWorkspaceIndex = source.findIndex(
        (workspaceItem) =>
          workspaceItem.name === sourceItem.workspace.name &&
          workspaceItem.workspaceCode === sourceItem.workspace.workspaceCode
      );
      const sourceItemIndex = source[sourceItemWorkspaceIndex].assays.findIndex(
        (item) => item.name === sourceItem.name && item.index === sourceItem.index
      );
      source[sourceItemWorkspaceIndex].assays.splice(sourceItemIndex, 1);
      if (source[sourceItemWorkspaceIndex].assays.length === 0) {
        source.splice(sourceItemWorkspaceIndex, 1);
      }
    });
    this.onChange();
  }

  moveAll(event, fromInclude = true) {
    event.preventDefault();
    event.stopPropagation();
    this.onTouch();
    let assaySelectorData = null;
    if (this.excluded.length && this.included.length) {
      assaySelectorData = this.convertWorkspaceToAssaySelector(this._workspaces);
    }
    if (fromInclude) {
      this.excluded = assaySelectorData ? [...assaySelectorData] : [...this.included];
      this.included = [];
    } else {
      this.included = assaySelectorData ? [...assaySelectorData] : [...this.excluded];
      this.excluded = [];
    }
    this.onChange();
  }

  writeValue(value: Array<WipWorkspaceSpec> | null): void {
    if (value == null) {
      // reset
      this._workspaces = [];
      this.included = [];
      this.excluded = [];
      this.form.markAsPristine();
      return;
    }

    if (!Array.isArray(value)) {
      return;
    }
    this.included = this.convertWorkspaceToAssaySelector(value);
    this.excluded = [];
    this.onChange();
  }

  convertWorkspaceToAssaySelector(value: Array<WipWorkspaceSpec>): Array<AssaySelectorItem> {
    return value.map((workspace) => {
      const workspaceCopy = { ...workspace };
      return {
        ...workspaceCopy,
        assays: workspaceCopy.assays.map((assay, index) => ({ name: assay, index, workspace: workspaceCopy })),
      };
    });
  }

  convertAssaySelectorToWorkspace(value: Array<AssaySelectorItem>): Array<WipWorkspaceSpec> {
    return value.map((workspace) => ({
      ...workspace,
      assays: workspace.assays.map((assay) => assay.name),
    }));
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange() {
    this.excludedSelected = [];
    this.includedSelected = [];
    this.form.setValue({ include: [], exclude: [] });

    if (this._onChange) {
      const mappedValue: Array<WipWorkspaceSpec> = this.convertAssaySelectorToWorkspace(this.excluded);
      this._onChange(mappedValue);
    }
  }

  onTouch() {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  ngOnDestroy() {
    this.includeValueChangesSub.unsubscribe();
    this.excludeValueChangesSub.unsubscribe();
  }
}
