import { Component, Inject, OnInit } from '@angular/core';

import { StateService } from '../../../state.service';

@Component({
  selector: 'app-worklist-report',
  templateUrl: './worklist-report.component.html',
  styleUrls: ['./worklist-report.component.scss'],
})
export class WorklistReportComponent implements OnInit {
  constructor(
    private stateService: StateService,
    @Inject('Window') public window: Window
  ) {}

  ngOnInit(): void {
    this.stateService.startLoading();
    let url = this.window.location.href;
    url = url.replace('/#', '');
    //user's shouldn't be in this component, probably an old bookmark, we'll just redirect them to the server
    this.window.location.replace(url);
  }
}
