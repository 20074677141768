import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuditComponent } from './reports/audit/audit.component';
import { WipFormComponent } from './reports/wip/wip-form/wip-form.component';
import { WipReportComponent } from './reports/wip/wip-report/wip-report.component';
import { WipWorkspaceResolver } from './reports/wip/wip-form/wip-workspace.resolver';
import { WorklistFormComponent } from './reports/worklist/worklist-form.component';
import { WorklistReportComponent } from './reports/worklist/worklist-report/worklist-report.component';
import { WipColumnResolver } from './reports/wip/wip-form/wip-column.resolver';
import { LabLocaleResolver } from './reports/lab-locale-resolver.service';
import { WipLabResolver } from './reports/wip/wip-form/wip-lab.resolver';
import { SortableColumnsResolver } from './reports/wip/wip-form/sortable-columns.resolver';

const routes: Routes = [
  {
    path: 'reports',
    resolve: { labLocale: LabLocaleResolver },
    children: [
      {
        path: 'worklist-form',
        runGuardsAndResolvers: 'always',
        component: WorklistFormComponent,
      },
      {
        path: 'worklist',
        data: { shouldReuseRoute: false },
        component: WorklistReportComponent,
      },
      {
        path: 'audit',
        component: AuditComponent,
      },
      {
        path: 'wip-form',
        component: WipFormComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          workspaces: WipWorkspaceResolver,
          wipColumns: WipColumnResolver,
          wipLabs: WipLabResolver,
          sortOptions: SortableColumnsResolver,
        },
      },
      {
        path: 'wip',
        component: WipReportComponent,
      },
    ],
  },
  { path: '**', redirectTo: `/reports/wip-form` },
  { path: '', redirectTo: `/reports/wip-form`, pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
