<div id="static-header" data-cy="static-header">
  <img src="/assets/images/logo.jpg" title="Clouded Leopard" id="clouded-leopard-logo" alt="Clouded Leopard logo" />
</div>

<div luxLayoutAlign="center center">
  <form
    name="worklist-report-form"
    [formGroup]="worklistReportForm"
    class="spot-form worklist-report-form"
    luxFlex="50%">
    <label class="spot-form__label"
      ><span>{{ 'LABELS.TESTS' | translate }}</span>
      <textarea
        data-cy="worklist-form-test-code-input"
        placeholder="{{ 'PLACEHOLDER.WORKLIST_TESTCODES' | translate }}"
        cols="100"
        rows="3"
        autocomplete="off"
        spellcheck="false"
        formControlName="testCodes"
        class="spot-form__input worklist-report-test-code-input"></textarea>
    </label>

    <div class="form-buttons-container" luxLayout="row" luxLayoutAlign="end center" luxLayoutGap="20px">
      <button
        type="submit"
        data-cy="worklist-form-submit-button"
        class="spot-button spot-button--primary"
        [ngClass]="{ 'spot-button--disabled': !submitEnabled() }"
        [disabled]="!submitEnabled()"
        (click)="submit()">
        {{ 'BUTTONS.SUBMIT' | translate }}
      </button>
      <button
        type="reset"
        data-cy="worklist-form-reset-button"
        class="spot-button spot-button--secondary"
        [ngClass]="{ 'spot-button--disabled': !submitEnabled() }"
        [disabled]="!submitEnabled()">
        {{ 'BUTTONS.RESET' | translate }}
      </button>
    </div>
  </form>
</div>
