import { Component, Inject, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StateService } from './state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject('Document') private document: Document,
    private stateService: StateService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.broadcastService.inProgress$
      .pipe(
        filter((progress) => {
          return progress === InteractionStatus.None;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
      });
  }

  ngOnInit(): void {
    this.stateService.loading.subscribe((loading) => {
      this.toggleAppLoader(loading);
    });
    this.stateService.stopLoading();
  }

  private toggleAppLoader(shouldDisplay: boolean) {
    this.document.getElementById('app-loader').style.display = shouldDisplay ? null : 'none';
  }
}
