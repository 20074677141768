<div class="assay-selector" [formGroup]="form">
  <div class="included">
    <label>{{ 'LABELS.INCLUDED' | translate }}</label>
    <select
      formControlName="include"
      (keydown.space)="move($event)"
      (keydown.enter)="move($event)"
      name="include"
      class="spot-form__select-input"
      multiple>
      <optgroup [label]="workspace.name" *ngFor="let workspace of included">
        <option [value]="assay" *ngFor="let assay of workspace.assays; trackBy: assaysTrackBy">
          {{ assay.name }}
        </option>
      </optgroup>
    </select>
  </div>
  <div class="toolbar">
    <button
      class="spot-button spot-button--link spot-button--large"
      data-cy="exclude-button"
      [ngClass]="{ 'spot-button--disabled': !includedSelected.length }"
      [disabled]="!includedSelected.length"
      (click)="move($event)">
      {{ 'LABELS.EXCLUDE' | translate }}
      <svg class="spot-icon spot-button__icon spot-button__icon--right" aria-labelledby="title">
        <title>next</title>
        <use xlink:href="./assets/icons/spot_icons.svg#next"></use>
      </svg>
    </button>
    <button
      class="spot-button spot-button--link spot-button--large"
      data-cy="exclude-all-button"
      [ngClass]="{ 'spot-button--disabled': !included.length }"
      [disabled]="!included.length"
      (click)="moveAll($event, true)">
      {{ 'LABELS.EXCLUDE_ALL' | translate }}
    </button>
    <button
      class="spot-button spot-button--link spot-button--large"
      data-cy="include-button"
      [ngClass]="{ 'spot-button--disabled': !excludedSelected.length }"
      [disabled]="!excludedSelected.length"
      (click)="move($event, false)">
      <svg class="spot-icon spot-button__icon spot-button__icon--right" aria-labelledby="title">
        <title>previous</title>
        <use xlink:href="./assets/icons/spot_icons.svg#previous"></use></svg
      >{{ 'LABELS.INCLUDE' | translate }}
    </button>
    <button
      class="spot-button spot-button--link spot-button--large"
      data-cy="include-all-button"
      [ngClass]="{ 'spot-button--disabled': !excluded.length }"
      [disabled]="!excluded.length"
      (click)="moveAll($event, false)">
      {{ 'LABELS.INCLUDE_ALL' | translate }}
    </button>
  </div>
  <div class="excluded">
    <label>{{ 'LABELS.EXCLUDED' | translate }}</label>
    <select
      formControlName="exclude"
      (keydown.space)="move($event, false)"
      (keydown.enter)="move($event, false)"
      name="exclude"
      class="spot-form__select-input"
      multiple>
      <optgroup [label]="workspace.name" *ngFor="let workspace of excluded">
        <option [value]="assay" *ngFor="let assay of workspace.assays; trackBy: assaysTrackBy">{{ assay.name }}</option>
      </optgroup>
    </select>
  </div>
</div>
