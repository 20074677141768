import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { LabLocale } from './reports.module';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { LabsService } from '@lims-common-ux/lux';

@Injectable({
  providedIn: 'root',
})
export class LabLocaleResolver {
  constructor(private translateService: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LabLocale> {
    const queryParams = route.queryParams;
    if (!queryParams.lab) {
      return throwError('No lab provided');
    }
    if (!queryParams.locale) {
      return throwError('No locale provided');
    }
    return this.translateService
      .use(queryParams.locale)
      .pipe(switchMap(() => of({ lab: queryParams.lab, locale: queryParams.locale } as LabLocale)));
  }
}
