import { inject, Injectable, InjectionToken } from '@angular/core';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import { FeaturesService, Link, LoggerService } from '@lims-common-ux/lux';
import { StaticData } from './static-data';
import { filter, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const pageResourcePath = '/index';

interface PageResource {
  _links: {
    self: Link;
    info: Link;
    workspaces: Link;
    features: Link;
    worklistReport: Link;
    wipReportColumns: Link;
    labs: Link;
    workspaceSpecs: Link;
    wipSortOptions: Link;
    wipReport: Link;
  };
}

export const STATIC_DATA = new InjectionToken<StaticData>('staticData', {
  factory(): StaticData {
    const service = inject(InitService);
    return service.staticAppData;
  },
  providedIn: 'root',
});

/**
 * Used as an app initializer, and because of that, the promise is guaranteed to be resolved before the app starts, and
 * the injection token for StaticData can be used safely to always provided a value, anywhere in the app.
 */
@Injectable({
  providedIn: 'root',
})
export class InitService {
  private _staticAppData: StaticData;
  get staticAppData() {
    return this._staticAppData;
  }

  get appEnvironment(): { production: boolean } {
    return environment;
  }

  constructor(
    private http: HttpClient,
    private featuresService: FeaturesService,
    private broadcaster: MsalBroadcastService,
    private loggerService: LoggerService
  ) {}

  initialize(): Promise<StaticData> {
    return firstValueFrom(
      this.broadcaster.inProgress$.pipe(
        filter((progress) => progress === InteractionStatus.None),
        switchMap(() => {
          return this.http.get<PageResource>(pageResourcePath).pipe(
            switchMap((pageResource) =>
              forkJoin([
                of(pageResource._links),
                this.featuresService.getFeatures(pageResource._links.features),
                this.appEnvironment.production ? this.loggerService.init() : of({}),
              ])
            ),
            map(([links, features]) => {
              this._staticAppData = {
                // Other things to load, links, translations(?)
                worklistReport: links.worklistReport,
                wipWorkspaces: links.workspaces,
                wipReportColumns: links.wipReportColumns,
                labs: links.labs,
                workspaceSpecs: links.workspaceSpecs,
                wipSortOptions: links.wipSortOptions,
                wipReport: links.wipReport,
                features,
              };
              return this._staticAppData;
            })
          );
        })
      )
    );
  }
}
