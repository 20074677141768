import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AuditComponent } from './audit/audit.component';
import { AssaysSelectorComponent } from './wip/wip-form/assays-selector/assays-selector.component';
import { WipFormComponent } from './wip/wip-form/wip-form.component';
import { WipReportComponent } from './wip/wip-report/wip-report.component';
import { WorklistFormComponent } from './worklist/worklist-form.component';
import { WorklistReportComponent } from './worklist/worklist-report/worklist-report.component';
import { LuxLayoutModule } from '@lims-common-ux/lux';

export interface LabLocale {
  lab: string;
  locale: string;
}
@NgModule({
  declarations: [
    AuditComponent,
    WipReportComponent,
    AssaysSelectorComponent,
    WipFormComponent,
    WorklistFormComponent,
    WorklistReportComponent,
  ],
  imports: [CommonModule, TranslateModule.forChild({}), FormsModule, ReactiveFormsModule, LuxLayoutModule],
})
export class ReportsModule {}
